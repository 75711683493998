<template>
  <div class="">
    <!-- section-3  -->
    <v-container class="mb-60">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between mb-4">
            <div class="d-flex align-center">
              <img
                v-svg-inline
                class="icon primary--text  mr-2"
                src="@/assets/images/icons/categories.svg"
                alt=""
              >

              <h2 class="font-weight-bold">
                Danh mục
              </h2>
            </div>
            <a
              href="#"
              class="text-decoration-none blue-grey--text darken-1"
            >
              View all
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>
        </v-col>
        <v-col
          v-for="(n, index) in category"
          :key="index"
          cols="6"
          sm="4"
          md="3"
          lg="2"
        >
          <base-card class="card-hover-shadow">
            <v-card-text>
              <div class="d-flex align-center">
                <p class="font-weight-bold mb-0  text-truncate">
                  {{ n.tenvn }}
                </p>
              </div>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- section-7  -->
    <v-container class="mb-60">
      <v-row>
        <v-col
          cols="12"
          lg="3"
          xl="2"
        >
          <base-card>
            <v-card-text>
              <div
                v-for="(brand, index) in brandsIcon"
                :key="index"
                class=""
              >
                <v-btn
                  block
                  class="mb-3 text-capitalize py-5"
                  elevation="0"
                  color="grey lighten-2 "
                >
                  <v-avatar
                    size="16"
                    tile
                    class="me-4"
                  >
                    <img
                      :src="brand.image"
                      alt=""
                    >
                  </v-avatar>
                  <span class="font-600">{{ brand.name }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col
          cols="12"
          lg="9"
          xl="10"
        >
          <div class="d-flex justify-space-between mb-5">
            <h2 class="font-weight-bold">
              Thế giới đồng hồ
            </h2>
            <a
              href="#"
              class="text-decoration-none blue-grey--text darken-1"
            >
              Xem tất cả
              <v-icon class="m-0 p-0">mdi-menu-right</v-icon>
            </a>
          </div>

          <v-row>
            <v-col
              v-for="(n, index) in suggest_items"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
              xl="3"
            >
              <card-cart
                card-hover-shadow
                :content-img="n.Image"
                :item-data="n"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- section-9  -->
    <v-container class="mb-60">
      <div class="row">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
        >
          <a href="#">
            <v-img
              contain
              src="@/assets/images/banners/banner-1.png"
            />
          </a>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="8"
        >
          <a href="#">
            <v-img
              contain
              src="@/assets/images/banners/banner-2.png"
            />
          </a>
        </v-col>
      </div>
    </v-container>

    <!-- section-13  -->
    <v-container class="mb-60">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
        >
          <base-card
            card-hover-shadow
            class=" py-48"
          >
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary--text text--darken-1 mr-0"
                  src="@/assets/images/icons/truck.svg"
                  alt=""
                >
              </div>
              <h4 class="font-600 grey--text text--darken-4 mb-3">
                Worldwide Delivery
              </h4>
              <v-card-subtitle
                class="text-center grey--text text--darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
        >
          <base-card
            card-hover-shadow
            class=" py-48"
          >
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary--text text--darken-1 mr-0"
                  src="@/assets/images/icons/credit.svg"
                  alt=""
                >
              </div>
              <h4 class="font-600 grey--text text--darken-4 mb-3">
                Safe Payment
              </h4>
              <v-card-subtitle
                class="text-center grey--text text--darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
        >
          <base-card
            card-hover-shadow
            class=" py-48"
          >
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary--text text--darken-1 mr-0"
                  src="@/assets/images/icons/shield.svg"
                  alt=""
                >
              </div>
              <h4 class="font-600 grey--text text--darken-4 mb-3">
                Shop With Confidence
              </h4>
              <v-card-subtitle
                class="text-center grey--text text--darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
        >
          <base-card
            card-hover-shadow
            class=" py-48"
          >
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div
                class="py-5 avatar-64 d-flex flex-row justify-center align-center grey lighten-2 rounded-circle mb-5"
              >
                <img
                  v-svg-inline
                  class="icon secondary--text text--darken-1 mr-0"
                  src="@/assets/images/icons/headphone.svg"
                  alt=""
                >
              </div>
              <h4 class="font-600 grey--text text--darken-4 mb-3">
                24/7 Support
              </h4>
              <v-card-subtitle
                class="text-center grey--text text--darken-2 pt-0"
              >
                We offer competitive prices on our 100 million plus product any
                range.
              </v-card-subtitle>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { LayDanhMuc, LaySuggestItems } from '@/plugins/helpers/fetch.js'
  import Navbar from '@/views/home/navbar'
  import CardCart from '@/components/cartCard/CardCart'
  export default {
    // name: 'Home',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Navbar,
      CardCart,
    },
    data () {
      return {
        // eslint-disable-next-line no-undef
        img: require('@/assets/images/banners/category-1.png'),
        slickSettings: {
          infinite: false,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        slickSettingsTwo: {
          infinite: false,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 1,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
        },
        slickSettingsThree: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        },
        brandsIcon: [
          {
            name: 'Seiko',
            image: require('@/assets/images/brands/v.png'),
          },
          {
            name: 'Citizen',
            image: require('@/assets/images/brands/u.png'),
          },
          {
            name: 'Casio',
            image: require('@/assets/images/brands/v.png'),
          },
          {
            name: 'Orient',
            image: require('@/assets/images/brands/u.png'),
          },
          {
            name: 'Credor',
            image: require('@/assets/images/brands/v.png'),
          },
        ],
        flashDeals: [
          {
            img: require('@/assets/images/products/flash-1.png'),
          },
          {
            img: require('@/assets/images/products/flash-2.png'),
          },
          {
            img: require('@/assets/images/products/flash-3.png'),
          },
          {
            img: require('@/assets/images/products/flash-4.png'),
          },
          {
            img: require('@/assets/images/products/flash-1.png'),
          },
          {
            img: require('@/assets/images/products/flash-2.png'),
          },
          {
            img: require('@/assets/images/products/flash-3.png'),
          },
          {
            img: require('@/assets/images/products/flash-4.png'),
          },
        ],
        topRatings: [
          {
            img: require('@/assets/images/products/camera-1.png'),
            title: 'Camera',
            price: '$3,300',
          },
          {
            img: require('@/assets/images/products/shoes-2.png'),
            title: 'Shoe',
            price: '$400',
          },
          {
            img: require('@/assets/images/products/mobile-1.png'),
            title: 'Mobile',
            price: '$999',
          },
          {
            img: require('@/assets/images/products/watch-1.png'),
            title: 'Watch',
            price: '$600',
          },
        ],
        newArrivals: [
          {
            img: require('@/assets/images/products/imagegoggles.png'),
            title: 'Sunglass',
            price: '$150',
          },
          {
            img: require('@/assets/images/products/lipstick(2).png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(2).png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/bgwatch.png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(4).png'),
            title: 'Green Plant',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(3).png'),
            title: 'Bonsai Tree',
            price: '$450',
          },
        ],
        bigDiscounts: [
          {
            img: require('@/assets/images/products/Electronics/7.beatsw3.png'),
            title: 'BenX 2020',
          },
          {
            img: require('@/assets/images/products/Electronics/9.SonyTV1080p.png'),
            title: 'Tony TV 1080p',
          },
          {
            img: require('@/assets/images/products/Electronics/10.SonyPS4.png'),
            title: 'Tony PS4',
          },
          {
            img: require('@/assets/images/products/Electronics/11.Netgear2020.png'),
            title: 'Setgearr 2020',
          },
          {
            img: require('@/assets/images/products/Electronics/12.SonyBGB.png'),
            title: 'Tony BGB',
          },
          {
            img: require('@/assets/images/products/Electronics/13.LGProducts.png'),
            title: 'RG Products',
          },
          {
            img: require('@/assets/images/products/Electronics/14.Panasonic2019.png'),
            title: 'Sanasonic 2019',
          },
          {
            img: require('@/assets/images/products/Electronics/15.DuneHD.png'),
            title: 'Zune HD',
          },
          {
            img: require('@/assets/images/products/Electronics/19.LGWashingMachine.png'),
            title: 'JK Washing Machine',
          },
        ],
        cars: [
          {
            img: require('@/assets/images/products/Automotive/1.Ford2019.png'),
            title: 'Lord 2019',
          },
          {
            img: require('@/assets/images/products/Automotive/2.Audi2017.png'),
            title: 'Budi 2017',
          },
          {
            img: require('@/assets/images/products/Automotive/3.Tesla2015.png'),
            title: 'Fesla 2015',
          },
          {
            img: require('@/assets/images/products/Automotive/4.Porsche2018.png'),
            title: 'Porsche 2018',
          },
          {
            img: require('@/assets/images/products/Automotive/5.Ford2018.png'),
            title: 'Ford 2018',
          },
          {
            img: require('@/assets/images/products/Automotive/6.Ford2020.png'),
            title: 'Ford 2020',
          },
          {
            img: require('@/assets/images/products/Automotive/7.Mitsubishi2018.png'),
            title: 'Mitsubishi 2018',
          },
          {
            img: require('@/assets/images/products/Automotive/8.BMW2019.png'),
            title: 'BMW 2019',
          },
        ],
        mobilePhones: [
          {
            img: require('@/assets/images/products/Electronics/29.AppleEarphones.png'),
          },
          {
            img: require('@/assets/images/products/Electronics/30.Nokiaandroidone.png'),
          },
          {
            img: require('@/assets/images/products/Electronics/4.LumixDSLR.png'),
          },
          {
            img: require('@/assets/images/products/Electronics/2.COSOR1.png'),
          },
          {
            img: require('@/assets/images/products/Electronics/20.TascuigoAriadryLight.png'),
          },
          {
            img: require('@/assets/images/products/Electronics/31.Symphonlights.png'),
          },
          {
            img: require('@/assets/images/products/flash-3.png'),
          },
          {
            img: require('@/assets/images/products/bgmobile1.png'),
          },
        ],
        opticsWatch: [
          {
            img: require('@/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/9.RayBanBlack.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/10.RayBanOcean.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/16.SkmeiWatchBlack.png'),
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png'),
          },
        ],
        moreForYou: [
          {
            img: require('@/assets/images/products/Bikes/6.Honda2019.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/7.NinjaKawasaki2016.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/9.HeroHonda2017.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/11.Kawasaki2020.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/13.RoyalEnfield2010.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/14.RoaylEnfield2011.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/15.PrimeXTZ.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/16.RoyalEnfield2012.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/18.NinjaKawasaki2019.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/20.CBR2022.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/21.TarzT3.png'),
          },
          {
            img: require('@/assets/images/products/Bikes/22.YamahaR15Black.png'),
          },
        ],
        topCategories: [
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
        ],
        featuredBrands: [
          {
            img: require('@/assets/images/products/london-britches.png'),
            title: 'London Britches',
          },
          {
            img: require('@/assets/images/products/jimandjiko.png'),
            title: 'Jim & Jago',
          },
        ],
        categories: [
          {
            img: require('@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png'),
            title: 'Tops',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png'),
            title: 'Smart Watch',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/10.RayBanOcean.png'),
            title: 'Sunglasses',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/3.BrownCap.png'),
            title: 'Cap',
          },
          {
            img: require('@/assets/images/products/Fashion/Shoes/1.NikeRed.png'),
            title: 'Nike Red',
          },
          {
            img: require('@/assets/images/products/Fashion/Shoes/5.NikeBlue.png'),
            title: 'Nike Silver',
          },
          {
            img: require('@/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png'),
            title: 'Gold Necklace',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png'),
            title: 'NBL Hoodie',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png'),
            title: 'Tops',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
        ],
        heroSlider: [
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: '50% Off For Your First Shopping',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: ' 20% Off For  First Electronics Purchase',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: 'Get 70% Off For  First  Grocery Purchase',
          },
        ],
        tab: null,
        items: ['Brands', 'Shops'],
        category: [],
        suggest_items: [],
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'System']),
    },
    mounted () {
      this.LayDanhMuc()
      this.LaySuggestItems()
    },
    methods: {
      LayDanhMuc () {
        LayDanhMuc().then(resp => {
          if (resp.status !== 200) {
            return Promise.reject(resp.status)
          }
          return resp.json()
        })
          .then(json => {
            this.category = json.data
          })
          .catch(err => {

          })
      },
      LaySuggestItems () {
        LaySuggestItems().then(resp => {
          if (resp.status !== 200) {
            return Promise.reject(resp.status)
          }
          return resp.json()
        })
          .then(json => {
            this.suggest_items = json.data
          })
          .catch(err => {

          })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
