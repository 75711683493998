import api from './api'
function LayDanhMuc (tatca = false) {
    let url = `${api.URL}/ya/category`
    if (tatca === true) {
        url = url + '/all'
    }
    return fetch(url)
}
function LaySuggestItems () {
    return fetch(`${api.URL}/ya/items`)
}
export { LayDanhMuc, LaySuggestItems }
